import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "toast__icon" }
const _hoisted_2 = { class: "toast__content" }
const _hoisted_3 = {
  key: 0,
  class: "toast__text"
}
const _hoisted_4 = {
  key: 1,
  class: "hash-wrap"
}
const _hoisted_5 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_linkIcon = _resolveComponent("linkIcon")
  const _component_close_icon = _resolveComponent("close-icon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toast", `toast--${$props.type}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.currentIcon)))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([{ hash: !!$props.hash }, "toast__title"])
      }, _toDisplayString($setup.txTitle), 3),
      (!$props.hash)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.text), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("a", {
              href: $props.hash,
              target: "_blank"
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('transactionsSocket.viewTitle')) + " ", 1),
              _createVNode(_component_linkIcon, { class: "hash-link" })
            ], 8, _hoisted_5)
          ]))
    ]),
    _createElementVNode("div", {
      class: "toast__close",
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('close')))
    }, [
      _createVNode(_component_close_icon)
    ])
  ], 2))
}