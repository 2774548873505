import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cat-page__content" }
const _hoisted_2 = { class: "cat-page__section" }
const _hoisted_3 = {
  key: 0,
  class: "cat-page__cat-icon"
}
const _hoisted_4 = { class: "cat-page__info" }
const _hoisted_5 = { class: "cat-page__title" }
const _hoisted_6 = { class: "cat-page__message" }
const _hoisted_7 = {
  key: 0,
  class: "cat-page__addresses"
}
const _hoisted_8 = { class: "cat-page__address" }
const _hoisted_9 = { class: "cat-page__add-address" }
const _hoisted_10 = { class: "cat-page__new-address" }
const _hoisted_11 = { class: "cat-page__icon" }
const _hoisted_12 = { class: "cat-page__address-line" }
const _hoisted_13 = { class: "cat-page__input" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_closeIcon = _resolveComponent("closeIcon")
  const _component_catIcon = _resolveComponent("catIcon")
  const _component_Input = _resolveComponent("Input")
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cat-page", { noCat: $props.data.length > 2 }])
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "cat-page__header" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_closeIcon, {
        class: "cat-page__close-icon",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('close')))
      }),
      _createElementVNode("div", _hoisted_2, [
        ($props.data.length <= 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_catIcon)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('catPage.congratulations')) + "! ", 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('catPage.message')), 1)
        ])
      ]),
      ($props.data)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortByAlphabet($props.data, 'net'), (wallet, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: `${wallet.address}_${wallet.net}`
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('catPage.addedAddress')), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.icons[wallet.net])))
                    ]),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(wallet?.address?.length >= 20
                    ? `${wallet?.address.slice(
                        0,
                        20
                      )}...${wallet?.address.slice(-20)}`
                    : wallet?.address), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_Input, {
                    id: `name-${index}`,
                    modelValue: $setup.aliases[`${wallet.net}_${wallet.address}`],
                    "onUpdate:modelValue": $event => (($setup.aliases[`${wallet.net}_${wallet.address}`]) = $event),
                    modelModifiers: { trim: true },
                    label: _ctx.$t('catPage.inputLabel'),
                    type: "text",
                    icon: wallet.type || $props.inputTypeIcon,
                    placeholder: $props.walletTypePlaceholder,
                    "data-qa": $props.dataQa && `${$props.dataQa}__cat-modal__alias-field`
                  }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "label", "icon", "placeholder", "data-qa"])
                ])
              ], 64))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_PrimaryButton, {
        disabled: $setup.disabled,
        "data-qa": "Ok",
        onClick: $setup.clickHandler
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ok')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ], 2))
}