import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobile-app-modal" }
const _hoisted_2 = { class: "mobile-app-modal__main" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mobile-app-modal__title" }
const _hoisted_5 = { class: "mobile-app-modal__info" }
const _hoisted_6 = { class: "mobile-app-modal__iost-button" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_closeIcon = _resolveComponent("closeIcon")
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "mobile-app-modal__header" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_closeIcon, {
        class: "mobile-app-modal__close-icon",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.$emit('close', $event)), ["stop"]))
      }),
      _createElementVNode("img", {
        src: $setup.downloadAppIcon,
        class: "mobile-app-modal__icon"
      }, null, 8, _hoisted_3),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('mobileAppModal.title')), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('mobileAppModal.info')), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_PrimaryButton, {
          color: "#FFFFFF",
          "bg-color": "#FF5722",
          "box-shadow": "0px 0px 25px rgba(255, 87, 34, 0.5)",
          onClick: _cache[1] || (_cache[1] = $event => (
            $setup.clickHandler(
              'https://apps.apple.com/us/app/citadel-one/id1546701475?ign-mpt=uo%3D2'
            )
          ))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('mobileAppModal.appStore')), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_PrimaryButton, {
        onClick: _cache[2] || (_cache[2] = $event => (
          $setup.clickHandler(
            'https://play.google.com/store/apps/details?id=one.citadel.mobile&gl=NL'
          )
        ))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('mobileAppModal.googlePlay')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}