import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "toasts" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Toast = _resolveComponent("Toast")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "slide-from-top" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.toasts, (toast) => {
          return (_openBlock(), _createBlock(_component_Toast, {
            key: toast.id,
            type: toast.type,
            title: toast.title,
            text: toast.text,
            hash: toast.hash,
            onClose: $event => ($setup.close(toast.id))
          }, null, 8, ["type", "title", "text", "hash", "onClose"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}