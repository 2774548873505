import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent } from "vue"
import _imports_0 from '@/assets/gif/loader.gif'


export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Modal = _resolveComponent("Modal")

  return ($setup.showLoader)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "fade"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Modal, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_resolveDynamicComponent($setup.layout), { key: 1 }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }))
}