import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal_content" }
const _hoisted_2 = { class: "modal_content__content" }
const _hoisted_3 = { class: "modal_content__title" }
const _hoisted_4 = { class: "modal_content__desc" }
const _hoisted_5 = { class: "modal__btn" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_closeIcon = _resolveComponent("closeIcon")
  const _component_addedIcon = _resolveComponent("addedIcon")
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "modal_content__header" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_closeIcon, {
        class: "modal_content__close-icon",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('close', $event)))
      }),
      _createVNode(_component_addedIcon, { class: "modal_content__image" }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('addedModal.title')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('addedModal.desc')), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_PrimaryButton, {
          onClick: _withModifiers($setup.clickHandler, ["stop"])
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Ok ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}